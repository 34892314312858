/**
 * Inspired by https://github.com/YChebotaev/json-decycle/blob/master/index.js
 * while supporting $ref properties.
 */

/**
 * Key used in serialized JSON to indicate a circular reference.
 * We use a specific key to avoid collisions with user-defined keys.
 *
 * /!\/!\/!\/!\/!\/!\/!\/!\
 * An object that includes this key will not be serialized/deserialized properly.
 * For a complete solution that would support any keys, we could implement the solution proposed here:
 * https://github.com/GitbookIO/gitbook-x/pull/3839#discussion_r974032921
 * /!\/!\/!\/!\/!\/!\/!\/!\
 */
const SECRET_REF_KEY = '__gitbook_$ref__';

/**
 * Check if value is an object.
 */
function isObject(value) {
    return (
        typeof value === 'object' &&
        value != null &&
        !(value instanceof Boolean) &&
        !(value instanceof Date) &&
        !(value instanceof Number) &&
        !(value instanceof RegExp) &&
        !(value instanceof String)
    );
}

/**
 * Encode a path in an object to a string that can be used in JSON and retrieved on parsing.
 */
function toPointer(parts) {
    return `#${parts
        .map((part) => String(part).replace(/~/g, '~0').replace(/\//g, '~1'))
        .join('/')}`;
}

/**
 * Returns a replacer function that can be used with JSON.stringify to remove circular references.
 */
export const decycle = () => {
    const paths = new WeakMap();

    return function replacer(key, value) {
        if (key !== SECRET_REF_KEY && isObject(value)) {
            const seen = paths.has(value);

            if (seen) {
                return { [SECRET_REF_KEY]: toPointer(paths.get(value)) };
            } else {
                paths.set(value, [...(paths.get(this) ?? []), key]);
            }
        }

        return value;
    };
};

/**
 * Returns a reviver function that can be used with JSON.parse to restore circular references.
 */
export function retrocycle() {
    const parents = new WeakMap();
    const keys = new WeakMap();
    const refs = new Set();

    function dereference(ref) {
        const parts = ref[SECRET_REF_KEY].slice(1).split('/');
        let key,
            value = this;

        for (let i = 0; i < parts.length; i++) {
            key = parts[i].replace(/~1/g, '/').replace(/~0/g, '~');
            value = value[key];
        }

        const parent = parents.get(ref);
        parent[keys.get(ref)] = value;
    }

    return function reviver(key: string, value: any) {
        if (key === SECRET_REF_KEY) {
            refs.add(this);
        } else if (isObject(value)) {
            const isRoot = key === '' && Object.keys(this).length === 1;
            if (isRoot) {
                refs.forEach(dereference, this);
            } else {
                parents.set(value, this);
                keys.set(value, key);
            }
        }

        return value;
    };
}

/**
 * This decycling logic is already used in the swagger-cache
 * Utilized here for extracing example from schemas.
 */
export function decycleStructure(value: any): any {
    return JSON.parse(JSON.stringify(value, decycle()));
}
