export const HTTP_METHODS = [
    'get',
    'post',
    'put',
    'delete',
    'patch',
    'options',
    'head',
    'connect',
    'trace',
] as const;

export type HTTP_METHOD = (typeof HTTP_METHODS)[number];

// path starts with version
export const versionRegex = /^\/v?[0-9]+\//;

// matches "/{param}" in path
export const paramInPathRegex = /\/{[a-zA-Z0-9_-]+\}/g;
